/* eslint-env browser */
import React, { Component } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);

class Thanks extends Component {
  render() {
    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - Image License"
        description="Image License Jennifer Mone Hill Artist."
        keywords={[
          'Image License',
          'Jennifer Moné Hill Artist',
          'Jen Hill',
          'Jennifer Moné Hill',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
          'Jen Hill',
          'Jen Hill Artist',
        ]}
        imageLink="/works/Spectrum"
        imgSrc="Spectrum-by-Jennifer-Mone-Hill-800.jpg"
        imgSrc2x="Spectrum-by-Jennifer-Mone-Hill-1600.jpg"
        alt="Spectrum by Jennifer Moné Hill"
      >
        <ContentBox>
          <h3>Image License</h3>
          <p>
            Digital Image License. You are permitted to view digital images from
            this site. You are not permitted to copy, take or or duplicate any
            images on this site without permission from Jennifer Hill. Any use
            or reproduction of Jennifer Hill digital images or any of her
            artworks without prior agreement or purchase is prohibited.
          </p>
        </ContentBox>
      </InfoPage>
    );
  }
}

Thanks.propTypes = {
  location: PropTypes.object,
};

export default Thanks;
